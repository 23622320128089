<template>
  <main>
    <HeroPage
      title="Perguntas e respostas"
      description="Dúvidas frequentes"
    ></HeroPage>
    <Breadcrumb :list="breadcrumbList"></Breadcrumb>
    <section>
      <div class="section-container">
        <slot></slot>
      </div>
    </section>
  </main>
</template>

<script>
import Vue from "vue";
import HeroPage from "@/components/HeroPage.vue";
import Breadcrumb from "@/components/elements/Breadcrumb.vue";

export default Vue.extend({
  name: "Questions",
  components: {
    HeroPage,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbList: [],
    };
  },
  mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
  },
});
</script>
