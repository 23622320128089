<template>
  <Layout>
    <div class="card-toview">
      <div
        class="card-toview-image"
        :style="'background-image: url(' + question.image + ')'"
      >
        <div class="card-tovuew-title">
          <h3>{{ question.title }}</h3>
          <p>Consulte ao lado as dúvidas mais frequentes</p>
        </div>
      </div>
      <div class="card-toview-body">
        <div class="card-toview-content">
          <div
            v-for="(data, index) in question.questions"
            :key="index"
            class="mb-4"
          >
            <div class="w-full">
              <div class="card-toview-question">
                <h5>{{ data.question }}</h5>
                <Button
                  color="white"
                  :background="openAnswer !== index ? 'lime-600' : 'gray-800'"
                  :icon="true"
                  @action="openThisAnswer(index)"
                >
                  <span v-show="openAnswer === index">
                    <i class="fa fa-minus"></i>
                  </span>
                  <span v-show="openAnswer !== index">
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </Button>
              </div>
              <fade-transition>
                <div class="card-toview-answer" v-if="openAnswer === index">
                  <p v-html="data.answer"></p>
                </div>
              </fade-transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Layout from "@/components/layout/question/Layout.vue";
import Button from "@/components/elements/Button.vue";
import { FadeTransition } from "vue2-transitions";

export default Vue.extend({
  name: "QuestionsToView",
  data() {
    return {
      question: {},
      itensQuestions: [],
      openAnswer: 0,
    };
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
  },
  components: {
    Layout,
    Button,
    FadeTransition,
  },
  methods: {
    openThisAnswer(index) {
      this.openAnswer = index;
    },
    toRegister() {
      this.$router.push({ name: "SubscriptionRegister" });
    },
  },
  mounted() {
    let slug = this.$route.params.slug;

    let question = this.getParamHotsite.cards.find(
      (card) => card.slug === slug
    );

    if (!question) {
      this.$router.push({ name: "Questions" });
    } else {
      this.question = question;
    }
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/sass/card";

.accordion-fade-slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s;
  }

  &-enter {
    transform: translateY(-25px);
    opacity: 0;
  }

  &-leave-to {
    transform: translateY(25px);
    opacity: 0;
  }
}
</style>
